import React from "react";
import { useOverrides } from "@quarkly/components";
import { Link, List, Icon, Text, Box, Section } from "@quarkly/widgets";
import { BsMap, BsEnvelope, BsClock } from "react-icons/bs";
const defaultProps = {
	"padding": "40px 0 150px 0",
	"background": "linear-gradient(180deg,rgba(0, 0, 0, 0.63) 0%,rgba(0, 0, 0, 0.54) 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://virolosist.live/media/5.jpg) 50% 50% /cover repeat scroll padding-box",
	"quarkly-title": "Contacts-7"
};
const overrides = {
	"list": {
		"kind": "List",
		"props": {
			"margin": "0px 0px 50px 0px",
			"padding": "12px 0px 12px 0px",
			"list-style-type": "none",
			"as": "ul",
			"display": "flex",
			"align-items": "center",
			"justify-content": "center"
		}
	},
	"link": {
		"kind": "Link",
		"props": {
			"href": "/index",
			"color": "white",
			"padding": "6px 32px 6px 32px",
			"text-decoration-line": "initial",
			"display": "flex",
			"font": "20px/30px sans-serif",
			"hover-color": "--lightD2",
			"transition": "background-color 0.1s ease 0s",
			"children": "Головна"
		}
	},
	"link1": {
		"kind": "Link",
		"props": {
			"href": "/menu",
			"color": "white",
			"padding": "6px 32px 6px 32px",
			"text-decoration-line": "initial",
			"display": "flex",
			"font": "20px/30px sans-serif",
			"hover-color": "--lightD2",
			"transition": "background-color 0.1s ease 0s",
			"children": "Меню"
		}
	},
	"link2": {
		"kind": "Link",
		"props": {
			"href": "/testimonials",
			"color": "white",
			"padding": "6px 32px 6px 32px",
			"text-decoration-line": "initial",
			"display": "flex",
			"font": "20px/30px sans-serif",
			"hover-color": "--lightD2",
			"transition": "background-color 0.1s ease 0s",
			"children": "Відгуки"
		}
	},
	"box": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"display": "grid",
			"grid-template-columns": "repeat(3, 1fr)",
			"grid-gap": "36px 34px",
			"margin": "0px 0px 0 0px",
			"sm-grid-template-columns": "1fr",
			"md-grid-template-columns": "1fr"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"display": "flex",
			"flex-direction": "column",
			"align-items": "center",
			"border-color": "--color-lightD2",
			"border-radius": "4px"
		}
	},
	"icon": {
		"kind": "Icon",
		"props": {
			"category": "bs",
			"icon": BsMap,
			"size": "54px",
			"margin": "0px 0px 20px 0px",
			"color": "#dae0e5"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 15px 0px",
			"font": "normal 500 28px/1.2 --fontFamily-sans",
			"text-align": "center",
			"color": "--light",
			"md-margin": "0px 0px 20px 0px",
			"children": "Адреса"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0 0px",
			"color": "#dae0e5",
			"font": "normal 300 20px/1.5 --fontFamily-sansHelvetica",
			"text-align": "center",
			"border-color": "#b8acac",
			"md-margin": "0px 0px 15px 0px",
			"children": "вулиця Князя Острозького 7, Рівне, 33000"
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"display": "flex",
			"flex-direction": "column",
			"align-items": "center",
			"border-color": "--color-lightD2",
			"border-radius": "4px"
		}
	},
	"icon1": {
		"kind": "Icon",
		"props": {
			"category": "bs",
			"icon": BsEnvelope,
			"size": "54px",
			"margin": "0px 0px 20px 0px",
			"color": "#dae0e5"
		}
	},
	"text2": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 15px 0px",
			"font": "normal 500 28px/1.2 --fontFamily-sans",
			"text-align": "center",
			"color": "--light",
			"md-margin": "0px 0px 20px 0px",
			"children": "Забронювати Столик"
		}
	},
	"link3": {
		"kind": "Link",
		"props": {
			"href": "tel:068 794 52 97",
			"color": "#dae0e5",
			"text-decoration-line": "initial",
			"font": "normal 300 20px/1.5 --fontFamily-sansHelvetica",
			"display": "block",
			"text-align": "center",
			"margin": "0px 0px 8px 0px",
			"children": "068 794 52 97"
		}
	},
	"link4": {
		"kind": "Link",
		"props": {
			"href": "mailto:admin@virolosist.live",
			"color": "#dae0e5",
			"text-decoration-line": "initial",
			"font": "normal 300 20px/1.5 --fontFamily-sansHelvetica",
			"display": "block",
			"margin": "0px 0px 0 0px",
			"text-align": "center",
			"children": "admin@virolosist.live"
		}
	},
	"box3": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"display": "flex",
			"flex-direction": "column",
			"align-items": "center",
			"border-color": "--color-lightD2",
			"border-radius": "4px"
		}
	},
	"icon2": {
		"kind": "Icon",
		"props": {
			"category": "bs",
			"icon": BsClock,
			"size": "54px",
			"margin": "0px 0px 20px 0px",
			"color": "#dae0e5"
		}
	},
	"text3": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 15px 0px",
			"font": "normal 500 28px/1.2 --fontFamily-sans",
			"text-align": "center",
			"color": "--light",
			"md-margin": "0px 0px 20px 0px",
			"children": "Працюємо"
		}
	},
	"text4": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 15px 0px",
			"color": "#dae0e5",
			"font": "normal 300 20px/1.5 --fontFamily-sansHelvetica",
			"text-align": "center",
			"border-color": "#b8acac",
			"children": "з 12:00 до 23:00"
		}
	}
};

const Footer = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<List {...override("list")}>
			<Link {...override("link")} />
			<Link {...override("link1")} />
			<Link {...override("link2")} />
		</List>
		<Box {...override("box")}>
			<Box {...override("box1")}>
				<Icon {...override("icon")} />
				<Text {...override("text")} />
				<Text {...override("text1")} />
			</Box>
			<Box {...override("box2")}>
				<Icon {...override("icon1")} />
				<Text {...override("text2")} />
				<Link {...override("link3")} />
				<Link {...override("link4")} />
			</Box>
			<Box {...override("box3")}>
				<Icon {...override("icon2")} />
				<Text {...override("text3")} />
				<Text {...override("text4")} />
			</Box>
		</Box>
		{children}
	</Section>;
};

Object.assign(Footer, { ...Section,
	defaultProps,
	overrides
});
export default Footer;